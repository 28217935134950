<template>
    <div class='container'>
        <HeaderNav ref="headerNav" />
        <div class="content">
            <div class="banner">
                <!-- 顶部搜索区域 -->
                <div class="searchBlock">
                    <!-- <div class="columnTip">
                        <div class="columns" v-for="item in columns" :key="item.id" @click=changeColumn(item.name)>
                            <div :class="{active:item.name==keyColumnName}">{{item.name}}</div>
                            <img v-if="item.name==keyColumnName" src="@/assets/img/triangle_down_topic.png" alt="">
                        </div>
                    </div> -->
                    <div class="searchBox">
                        <input type="text" placeholder="搜索名称 / 关键词" v-model="keyword" @keyup.enter="goSearch"
                            maxlength="50" />
                        <img src="@/assets/img/searchIcon.png" alt="" class="searchIcon" @click="goSearch">
                    </div>
                    <div class="quickTip">
                        <span>热门搜索:</span>
                        <span class="hotKey" v-for="item in hotKeys" :key="item.id"
                            @click="setHotKey(item.name)">{{item.name}}</span>
                    </div>
                </div>
            </div>
            <div class="inner">
                <!-- 左侧 -->
                <div class="left">
                    <!-- 当前项目信息 -->
                    <div class="projectInfo">
                        <div class="tit">{{topicDetail?topicDetail.title:"数据已失效，请浏览其他数据"}}</div>
                        <div class="tips" v-if="topicDetail">
                            <!-- <div class="tip areaTip">{{topicDetail.regionName}}</div> -->
                            <div class="tip columnTip">{{topicDetail.managementName}}</div>
                            <!-- <div class="tip industryTip" v-for="(item,index) in (topicDetail.classificationName ? topicDetail.classificationName.split(',') : [])" :key="index + item">{{item}}</div> -->
                            <!-- <div class="tip industryTip">{{topicDetail.classificationName}}</div> -->
                        </div>
                        <div class="otherInfo" v-if="topicDetail">
                            <div class="projectTime">
                                <img class="time_icon" src="@/assets/img/time_icon.png" alt="">
                                <span>发布时间：{{topicDetail.createDate}}</span>
                            </div>
                            <div class="projectHandle">
                                <div class="shareBox">
                                    <div class="qrcodeBox">
                                        <div>微信扫描二维码</div>
                                        <div ref="qrCodeUrl"></div>
                                        <div>点击右上角分享到朋友圈</div>
                                    </div>
                                    <img src="@/assets/img/share_icon.png" alt="">
                                </div>
                                <div>
                                    <img v-if="userInfo&&topicDetail.collectionType==0"
                                        src="@/assets/img/collect_back.png" alt="" @click="toggleCollect(false)">
                                    <img v-else-if="userInfo&&topicDetail.collectionType==1"
                                        src="@/assets/img/en_collect.png" alt="" @click="toggleCollect(true)">
                                    <img v-if="!userInfo" src="@/assets/img/collect_back.png" alt=""
                                        @click="toggleCollect(false)">
                                </div>

                            </div>
                        </div>
                        <!-- 会员显示内容 -->
                        <div class="vipContent" v-if="topicDetail&&topicDetail.showType==1">
                            <div v-html="topicDetail.content" style="word-break:break-all;"></div>
                            <div class="fileBox" v-if="topicFiles.length>0">
                                <div class="tit">附件列表</div>
                                <div class="file" v-for="item in topicFiles" :key="item.urlName"
                                    @click="downloadFile(item.urlName)">{{item.name}}</div>
                            </div>
                        </div>
                        <!-- 非会员显示内容 -->
                        <div class="unVipContent" v-if="topicDetail&&topicDetail.showType==0">
                            <div class="unVipTit">项目概况</div>
                            <div class="unVipTable">
                                <div class="row" v-for="(item,rowIndex) in unVipTable" :key="rowIndex">
                                    <div class="col" v-for="(col,colIndex) in item" :key="colIndex">
                                        <div class="colKey">{{col.colKey}}</div>
                                        <div class="colVal"
                                            v-if="col.colKey!='更新时间'&&col.colKey!='工程地区'&&col.colKey!='项目类别'">对付费会员开放
                                        </div>
                                        <div class="colVal" style="font-size:14px" v-else-if="col.colKey=='工程地区'">
                                            <!-- <div style="overflow:scroll;white-space:nowrap;"> -->
                                            {{topicDetail.regionName}}
                                            <!-- </div> -->
                                        </div>
                                        <div class="colVal" style="font-size:14px" v-else-if="col.colKey=='项目类别'">
                                            {{topicDetail.classificationName.split(',')[0]}}</div>
                                        <div class="colVal" style="font-size:12px" v-else-if="col.colKey=='更新时间'">
                                            {{topicDetail.createDate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="unVipText">
                                <div class="shadowBox"></div>
                                <div v-html="topicDetail.limitContent">
                                </div>
                            </div>
                            <div v-if="!userInfo||topicDetail.showType==0" class="unVipContent">
                                <img src="@/assets/img/401err.png" alt="">
                            </div>
                            <div class="unVipTip">
                                <div v-if="!userInfo">项目详细信息仅对正式会员开放</div>
                                <div v-if="!userInfo">
                                    请
                                    <span @click="goSign">注册会员</span>
                                    或
                                    <span @click="callLogin">登录账号</span>
                                    查看
                                </div>
                                <div>欢迎<span @click="goContact">联系客服</span>咨询相关事宜 </div>
                            </div>
                            <div class="fileBox" v-if="topicFiles.length>0">
                                <div class="tit">附件列表</div>
                                <div class="file" v-for="item in topicFiles" :key="item.urlName"
                                    @click="downloadFile(item.urlName)">{{item.name}}</div>
                            </div>
                        </div>
                    </div>
                    <!-- 您可能感兴趣文章 -->
                    <div class="pushProjects" v-if="pushArr1.length">
                        <div class="list-tit">您可能感兴趣</div>
                        <div class="list">
                            <div class="row" v-for="item in pushArr1" :key="item.uuid"
                                @click="goTopicDetail(item.uuid)">
                                <div class="topicTit">{{item.title}}</div>
                                <div class="topicInfo">
                                    <div class="tips">
                                        <div class="tip areaTip">{{item.regionName}}</div>
                                        <div class="tip columnTip">{{item.managementName}}</div>
                                        <!-- <div class="tip industryTip">{{item.classificationName}}</div> -->
                                    </div>
                                    <div class="topicTime">{{item.createDate}}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- 右侧 -->
                <div class="right">
                    <!-- 右侧轮播 -->
                    <div class="rightAdwheel" @mouseenter="pauseRollRight">
                        <div class="adwheel-tit">
                            优秀供应商
                        </div>
                        <div class="imgBox">
                            <div class="imgs" :style="{height:rightAdvHeight+'px','margin-top':rightAdvMt+'px'}">
                                <img :src="$RESOURCE_URL+item.picture" alt="" v-for="(item,index) in rightAdvs"
                                    :key="item.id+index" @click="openWeb(item.url)">
                            </div>
                        </div>
                    </div>
                    <!-- 右侧项目推荐 -->
                    <div class="rightPushProject">
                        <div class="rightPushProject-tit">{{pushColumn.name}}</div>
                        <div class="rightPushItem" v-for="item in pushColumnTopics" :key="item.uuid"
                            @click="goTopicDetail(item.uuid)">
                            <div class="item-tit">
                                {{item.title}}
                            </div>
                            <div class="item-info">
                                <div class="item-tags">
                                    <span>{{item.regionName}}</span>
                                    <span>{{item.managementName}}</span>
                                    <!-- <span>{{item.classificationName}}</span> -->
                                </div>
                                <div class="item-time">
                                    {{item.createDate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterNav />
        <SlideFixNav />
    </div>
</template>

<script>
    import wx from 'weixin-js-sdk';
    import QRCode from 'qrcodejs2'
    import HeaderNav from "@/components/HeaderNav"
    import FooterNav from "@/components/FooterNav"
    import SIdentify from "@/components/SIdentify"
    import SlideFixNav from "@/components/SlideFixNav"
    import {
        getHomepage,
    } from '@/request/homepage';
    import {
        getHotKey,
        searchTopic
    } from "@/request/search";
    import {
        viewCount
    } from "@/request/viewCount"
    import {
        columnManagement
    } from "@/request/column"
    import {
        getTopic,
        getTopicVip,
        collectTopic,
        channelSave
    } from "@/request/topic"
    import {
        getWxSharInfo
    } from "@/request/webinfo";
    export default {
        name: 'Topic',
        props: {

        },
        data() {
            return {
                //非会员表格
                unVipTable: [
                    [{
                        colKey: "工程地区"
                    }, {
                        colKey: "项目类别"
                    }, {
                        colKey: "更新时间"
                    }],
                    [{
                        colKey: "投资金额"
                    }, {
                        colKey: "进展阶段"
                    }, {
                        colKey: "建设周期"
                    }],
                    [{
                        colKey: "建筑面积"
                    }, {
                        colKey: "资金情况"
                    }, {
                        colKey: "详细地址"
                    }]
                ],
                //搜索
                columns: [], //栏目
                keyColumnName: "",
                hotKeys: [{}, {}, {}, {}, {}], //热搜关键字
                keyword: "", //搜索关键字
                //文章数据对象
                topicDetail: {},
                //文章附件信息
                topicFiles: [],
                //中部轮播
                rightAdvs: [],
                rightAdvHeight: 800,
                rightAdvMt: 0,
                rigthAdvIndex: 0,
                rigthAdvRollSeconds: 5000,
                rigthAdvRollInt: undefined,
                rigthAdvRollPause: undefined,
                //推荐列表
                pushArr1: [],
                pushColumn: {},
                pushColumnTopics: []
            };
        },
        computed: {

        },
        created() {
            viewCount(); //访问计数
            if (this.judgeAgent()) {
                this.wxShare();
            }
            //渠道统计
            if (this.$route.query.from == "email") {
                //邮件推送
                channelSave(0).then(res => {})
            } else {
                channelSave(2).then(res => {})
            }
        },
        mounted() {
            //轮播数据
            getHomepage({}).then(res => {
                this.rightAdvs = res.data.rightPictureList;
                this.setRightAdvHeight(this.rightAdvs)
                this.startRollRight()
            })
            getHotKey({}).then(res => {
                this.hotKeys = res.data;
            })
            columnManagement({}).then(res => {
                this.columns = res.data.filter(item => {
                    return item.name != "全部"
                });
            })
            this.loadData(this.$route.query.id)
            this.loadPushColumn()
            this.creatQrCode()
        },
        beforeDestroy() {},
        watch: {
            userInfo(val) {
                if (val) {
                    this.loadData(this.$route.query.id)
                }
            },
            "keyword"(val) {
                this.$refs.headerNav.keyword = val;
            }
        },
        methods: {
            judgeAgent() { //判断用户打开的设备
                let flag = false;
                var browser = {
                    versions: function () {
                        var u = navigator.userAgent,
                            app = navigator.appVersion;
                        return { //移动终端浏览器版本信息
                            trident: u.indexOf('Trident') > -1, //IE内核
                            presto: u.indexOf('Presto') > -1, //opera内核
                            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
                            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
                            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
                            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
                            iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
                            iPad: u.indexOf('iPad') > -1, //是否iPad
                            webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
                        };
                    }(),
                    language: (navigator.browserLanguage || navigator.language).toLowerCase()
                }
                if (browser.versions.mobile) { //判断是否是移动设备打开。browser代码在下面
                    var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
                    if (ua.match(/MicroMessenger/i) == "micromessenger") {
                        //在微信中打开
                        flag = true;
                    }

                }
                return flag;
            },
            //加载数据
            loadData(id) {
                console.log()
                //已登录
                if (this.$store.state.userInfo) {
                    getTopicVip(null, {
                        id: id
                    }).then(res => {
                        if (!res.data) {
                            this.topicDetail = null;
                            return;
                        }
                        this.topicDetail = res.data;
                        this.topicDetail.createDate = this.topicDetail.createDate.substring(0, 11);
                        this.topicDetail.regionName = this.topicDetail.regionName.substring(0, 3);
                        window.document.title = this.topicDetail.title;
                        this.keyColumnName = this.topicDetail.managementName;
                        this.topicFiles = this.topicDetail.file ? JSON.parse(this.topicDetail.file) : [];
                        //动态修改meta
                        if (this.topicDetail.showType == 1) {
                            document.getElementById('description').content = this.topicDetail.content
                        } else {
                            document.getElementById('description').content = this.topicDetail.limitContent
                        }
                        this.loadPushData({
                            pageSize: 11,
                            uuid: id,
                            classificationId: this.topicDetail.classificationId.split(",")[0],
                            classificationName: this.topicDetail.classificationName.split(",")[0],
                            managementId: this.topicDetail.managementId,
                            managementName: this.topicDetail.managementName,
                            regionName: this.topicDetail.regionName
                        })
                    })
                } else {
                    //未登录
                    getTopic(null, {
                        id: id
                    }).then(res => {
                        if (!res.data) {
                            this.topicDetail = null;
                            return;
                        }
                        this.topicDetail = res.data
                        window.document.title = this.topicDetail.title;
                        //动态修改meta
                        if (this.topicDetail.showType == 1) {
                            document.getElementById('description').content = this.topicDetail.content
                        } else {
                            document.getElementById('description').content = this.topicDetail.limitContent
                        }
                        //2021/10/09 临时需求：不登录也显示附件
                        this.topicFiles = this.topicDetail.file ? JSON.parse(this.topicDetail.file) : [];
                        this.loadPushData({
                            pageSize: 11,
                            uuid: id,
                            classificationId: this.topicDetail.classificationId.split(",")[0],
                            classificationName: this.topicDetail.classificationName.split(",")[0],
                            managementId: this.topicDetail.managementId,
                            managementName: this.topicDetail.managementName,
                            regionName: this.topicDetail.regionName
                        })
                    })
                }
            },
            //加载感兴趣推荐数据
            loadPushData(opt) {
                searchTopic(opt).then(res => {
                    //slice 0,13
                    this.pushArr1 = res.data.records.slice(0, 5).map(item => {
                        item.regionName = item.regionName.substring(0, 3);
                        item.createDate = item.createDate.substring(0, 10);
                        return item;
                    });
                })
            },
            //加载推荐栏目
            loadPushColumn() {
                columnManagement({
                    detailDisplay: 1
                }).then(res => {
                    this.pushColumn = res.data[0];
                    searchTopic({
                        managementName: this.pushColumn.name,
                        managementId: this.pushColumn.id,
                        pageSize: 5 //原10
                    }).then(res => {
                        this.pushColumnTopics = res.data.records.map(item => {
                            item.regionName = item.regionName.substring(0, 3);
                            item.createDate = item.createDate.substring(0, 10);
                            return item
                        });
                    })
                })
            },
            //收藏
            toggleCollect(boolean) {
                //boolean true 已收藏->取消收藏  false 未收藏->收藏
                //判断登录，未登录提示登录（HeaderNav登录事件）
                if (this.$store.state.userInfo) {
                    if (boolean) {
                        collectTopic(this.topicDetail).then(res => {
                            this.$set(this.topicDetail, "collectionType", 0)
                            this.$message.success("取消收藏成功")
                        })
                    } else {
                        collectTopic(this.topicDetail).then(res => {
                            this.$set(this.topicDetail, "collectionType", 1)
                            this.$message.success("收藏成功")
                        })
                    }
                } else {
                    this.callLogin()
                }

            },
            //headerNav顶部登录事件
            callLogin() {
                this.$refs.headerNav.openLoginDialog()
            },
            creatQrCode() {
                var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                    text: window.location.href,
                    width: 200,
                    height: 200,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            },
            //微信分享
            wxShare() {
                getWxSharInfo({
                    url: window.location.href
                }).then(res => {
                    let {
                        appId,
                        nonceStr,
                        signature,
                        timestamp
                    } = res.data;
                    wx.config({
                        debug: false,
                        // 开启调试模式,调用的所有api的返回值会在客户端alert出来，
                        //若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: appId, // 必填，公众号的唯一标识   在公众号后台找    也可以后端返回
                        timestamp: timestamp, // 必填，生成签名的时间戳  后端返回  示例 : 1566466330
                        nonceStr: nonceStr, // 必填，生成签名的随机串   后端返回  示例 :  'qcmjlufmkiu6ls3pm9fl6zpw4upaphpu'  
                        signature: signature, // 必填，签名   后端返回   示例 : 898c11a276d3ac322ac8fa2a1c9c4478d911bd3f
                        jsApiList: ["updateAppMessageShareData",
                            "updateTimelineShareData"
                        ] // 必填，需要使用的JS接口列表
                    });
                    wx.ready(function () {
                        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，
                        // 所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
                        wx.updateAppMessageShareData({
                            title: "朴谷网", // 分享标题
                            desc: window.document.title, // 分享描述
                            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: 'https://www.puguweb.com/img/shareLogo.jpg', // 分享图标
                            success: function () {
                                // 设置成功
                            }
                        })
                        wx.updateTimelineShareData({
                            title: window.document.title, // 分享标题
                            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: 'https://www.puguweb.com/img/shareLogo.jpg', // 分享图标
                            success: function (res) {
                                // 设置成功
                                console.log(res)
                            }
                        })
                    });
                });
            },
            goContact() {
                this.$router.push({
                    name: "Contact"
                })
            },
            /** 搜索框 */
            //搜索框触发搜索
            callSearch() {

            },
            //搜索框选择栏目
            changeColumn(name) {
                this.keyColumnName = name;
            },
            //选中热搜关键词
            setHotKey(key) {
                this.keyword = key;
                this.goSearch()
            },
            /** 页面跳转 */
            //注册
            goSign() {
                this.$router.push({
                    name: "Sign"
                })
            },

            /** 轮播图 */
            //右侧轮播
            //设置轮播容器高度
            setRightAdvHeight(arr) {
                this.rightAdvHeight = arr.length * 180;
            },

            //轮播
            startRollRight() {
                let that = this;
                if (that.rightAdvs.length > 5) {
                    //开启轮播
                    this.rigthAdvRollInt = setInterval(function () {
                        if (that.rigthAdvIndex < that.rightAdvs.length - 5) {
                            that.rigthAdvIndex = that.rigthAdvIndex + 1;
                            that.rightAdvs.push(that.rightAdvs[that.rigthAdvIndex - 1])
                        }
                        that.rightAdvMt = -180 * that.rigthAdvIndex;
                    }, this.rigthAdvRollSeconds)
                }
            },
            closeRollRight() {
                clearInterval(this.rigthAdvRollInt);
                this.rigthAdvRollInt = undefined;
            },
            //暂停轮播
            pauseRollRight() {
                let that = this;
                this.closeRollRight();
                if (this.rigthAdvRollPause) {
                    clearTimeout(that.rigthAdvRollPause)
                }
                this.rigthAdvRollPause = setTimeout(function () {
                    that.startRollRight();
                    that.rigthAdvRollPause = undefined;
                }, that.rigthAdvRollSeconds)
            },
            //链接跳转
            openWeb(url) {
                if (url) {
                    window.open(url, "_blank");
                }
            },
            downloadFile(url) {
                window.open(this.$RESOURCE_URL + url)
            },
            //本页刷新数据
            goTopicDetail(id) {
                window.scroll(0, 0);
                this.loadData(id)
            },

            goSearch() {
                let column = undefined;
                for (let i = 0; i < this.columns.length; i++) {
                    if (this.columns[i].name == this.keyColumnName) {
                        column = this.columns[i]
                        break
                    }
                }
                let query = {
                    keyword: this.keyword,
                }
                if (column) {
                    query.managementName = column.name;
                    query.managementId = column.id;
                }
                this.$router.push({
                    name: "Search",
                    query: query
                })
            }

        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo
            }
        },
        components: {
            HeaderNav,
            FooterNav,
            SIdentify,
            SlideFixNav
        },
    };
</script>
<style scoped lang="scss">
    .inner {
        width: 1200px;
        margin: 0 auto;
    }

    .content {
        width: 100%;
        background-image: url("../assets/img/indexBanner.png");
        background-repeat: no-repeat;
        background-size: 100%;


        .banner {
            width: 902px;
            margin: 0 auto;
            position: relative;
            padding: 40px 0;
            z-index: 0;

            .searchBlock {
                .columnTip {
                    width: 902px;
                    padding-left: 25px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    padding-bottom: 9px;

                    .columns {
                        text-align: center;
                        margin-right: 17px;
                        font-size: 14px;
                        color: white;
                        cursor: pointer;


                        div {
                            border-radius: 16px;
                            padding: 6px 7px;
                            padding-bottom: 12px;
                        }

                        .active {
                            background-color: #4385f5;
                            padding-bottom: 6px;
                        }

                        img {
                            width: 10px;
                            height: 6px;
                            display: block;
                            margin: 0 auto;
                        }
                    }

                }

                .searchBox {
                    height: 55px;
                    padding: 0 35px;
                    margin-right: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 28px;
                    background: rgba(255, 255, 255, 0.8);
                    font-size: 14px;
                    margin-bottom: 10px;


                    input {
                        background: transparent;
                        color: #333333;
                        width: 520px;
                        height: 55px;
                        line-height: 55px;
                        font-size: 16px;
                    }

                    .searchIcon {
                        width: 23px;
                        height: 23px;
                    }
                }

                .quickTip {
                    padding-left: 25px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 20px;

                    span {
                        margin-right: 12px;
                    }

                    .hotKey {
                        cursor: pointer;
                    }

                    .hotKey:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .inner {
            width: 1290px;
            display: flex;
            justify-content: space-between;
            padding-bottom: 24px;
            z-index: 100;

            .left {
                width: 960px;

                .projectInfo {
                    overflow-x: auto;
                    width: 960px;
                    min-height: 700px;
                    height: auto;
                    box-sizing: border-box;
                    padding: 24px 20px;
                    background: #ffffff;
                    border-radius: 16px;
                    box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21);
                    margin-bottom: 28px;

                    .tit {
                        margin-bottom: 17px;
                        font-size: 20px;
                        color: #333;
                    }

                    .tips {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        margin-bottom: 26px;

                        .tip {
                            // width: 80px;
                            height: 28px;
                            box-sizing: border-box;
                            margin-bottom: 10px;
                            padding: 0 20px;
                            border-radius: 14px;
                            font-weight: 400;
                            text-align: center;
                            color: #ffffff;
                            line-height: 28px;
                            margin-right: 9px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .tip:last-child {
                            margin-right: 0;
                        }

                        .areaTip {
                            background: #2984ff;
                        }

                        .columnTip {
                            background: #00b9d4;
                        }

                        .industryTip {
                            background: #ff8b00;
                        }
                    }

                    .otherInfo {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-bottom: 9px;
                        margin-bottom: 20px;
                        border-bottom: 1px solid #d8d8d8;

                        .projectTime {
                            display: flex;
                            align-items: center;
                            color: #666666;
                            font-size: 16px;

                            .time_icon {
                                width: 20px;
                                height: 20px;
                                display: block;
                                margin-right: 10px;
                            }
                        }

                        .projectHandle {
                            width: 70px;
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            div {
                                position: relative;

                                img {
                                    width: 20px;
                                    height: 20px;
                                    display: block;
                                    cursor: pointer;
                                }
                            }

                            .shareBox {
                                position: relative;


                                .qrcodeBox {
                                    opacity: 0;
                                    height: 0;
                                    overflow: hidden;
                                    position: absolute;
                                    left: -260px;
                                    top: -120px;
                                    padding: 10px 20px;
                                    background-color: #fff;
                                    text-align: center;
                                    box-shadow: 5px 5px 10px 0px #000;
                                    transition: all 500ms;
                                    z-index: 100;

                                    div:first-child {
                                        margin-bottom: 2px;
                                    }
                                }
                            }

                            .shareBox:hover {
                                .qrcodeBox {
                                    opacity: 1;
                                    height: 238px;
                                    cursor: pointer;
                                }
                            }

                        }
                    }

                    // 非会员显示内容
                    .unVipContent {
                        .unVipTit {
                            color: #266ddc;
                            font-size: 20px;
                            margin-bottom: 9px;
                        }

                        .unVipTable {
                            width: 917px;
                            height: 160px;
                            overflow: hidden;
                            border: 1px solid #e4eef6;
                            margin-bottom: 24px;

                            .row {
                                display: flex;
                                align-items: center;
                                border-bottom: 1px solid #e4eef6;

                                .col {
                                    display: flex;
                                    align-items: center;
                                    line-height: 54px;
                                    text-align: center;
                                    border-right: 1px solid #e4eef6;

                                    .colKey {
                                        width: 133px;
                                        height: 54px;
                                        font-size: 16px;
                                        background-color: #f1f7fd;

                                    }

                                    .colVal {
                                        width: 141px;
                                        height: 54px;
                                        background-color: #fff;
                                        font-size: 14px;
                                    }
                                }

                                .col:last-child {
                                    border: none;
                                }
                            }

                            .row:last-child {
                                border: none;
                            }
                        }

                        .unVipText {
                            position: relative;
                            width: 917px;
                            font-size: 16px;
                            font-weight: 400;
                            text-align: left;
                            color: #666666;
                            line-height: 22px;
                            margin-bottom: 20px;

                            div {
                                position: relative;
                                z-index: 0;
                            }

                            .shadowBox {
                                position: absolute;
                                width: 917px;
                                height: 100%;
                                z-index: 1;
                                box-shadow: 0px -8px 5px 0px #fff inset;
                            }
                        }

                        .unVipContent {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #333333;
                            font-size: 16px;

                            div {
                                flex: 1;
                                text-align: center;
                            }

                            span {
                                color: #266ddc;
                                cursor: pointer;
                            }

                            span:hover {
                                text-decoration: underline;
                            }

                            &>img {
                                display: block;
                                width: 211px;
                                height: 192px;
                            }
                        }

                        .unVipTip {
                            display: flex;
                            padding-top: 32px;
                            align-items: center;
                            justify-content: center;
                            color: #333333;
                            font-size: 16px;

                            div {
                                flex: 1;
                                text-align: center;
                            }

                            span {
                                color: #266ddc;
                                font-weight: bold;
                                text-decoration: underline;
                                cursor: pointer;
                            }

                            span:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    .vipContent {
                        font-size: 16px;

                        .fileBox {
                            .tit {
                                font-size: 16px;
                                font-weight: 500;
                                color: #266ddc;
                            }

                            .file {
                                max-width: 200px;
                                margin-bottom: 2px;
                                cursor: pointer;
                            }

                            .file:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                }

                .pushProjects {
                    position: relative;
                    background: #ffffff;
                    border-radius: 16px;
                    box-sizing: border-box;
                    padding: 18px 0;
                    box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21);

                    .list-tit {
                        width: 178px;
                        margin-left: 22px;
                        font-size: 20px;
                        font-weight: bold;
                        color: #266ddc;
                        text-align: center;
                        background-image: url("../assets/img/list_tit_left.png"), url("../assets/img/list_tit_right.png");
                        background-repeat: no-repeat;
                        background-position: left center, right center;
                        background-size: 14px 14px;
                        margin-bottom: 4px;
                    }

                    .list {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-wrap: wrap;

                        .row {
                            width: 960px;
                            padding: 1px 22px;
                            box-sizing: border-box;
                            border-bottom: 1px solid #f5f5f5;
                            cursor: pointer;

                            .topicTit {
                                width: 816px;
                                padding: 12px 0;
                                font-size: 16px;
                                font-weight: 400;
                                text-align: left;
                                color: #333333;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }

                            .topicInfo {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin-bottom: 10px;

                                .topicTime {
                                    font-size: 16px;
                                    font-weight: 400;
                                    text-align: left;
                                    color: #999999;
                                    line-height: 22px;
                                }

                                .tips {
                                    display: flex;
                                    align-items: center;

                                    .tip {
                                        // width: 80px;
                                        height: 28px;
                                        box-sizing: border-box;
                                        padding: 0 20px;
                                        border-radius: 14px;
                                        font-weight: 400;
                                        text-align: center;
                                        color: #ffffff;
                                        line-height: 28px;
                                        margin-right: 9px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    .tip:last-child {
                                        margin-right: 0;
                                    }

                                    .areaTip {
                                        background: #2984ff;
                                    }

                                    .columnTip {
                                        background: #00b9d4;
                                    }

                                    .industryTip {
                                        background: #ff8b00;
                                    }
                                }

                            }



                        }

                        .row:hover {
                            .topicTit {
                                color: #266ddc;
                            }

                            .topicTime {
                                color: #266ddc;
                            }
                        }

                    }
                }
            }

            .right {
                width: 314px;
                box-sizing: border-box;

                .rightAdwheel {
                    width: 314px;
                    height: 978px;
                    padding: 20px 16px;
                    box-sizing: border-box;
                    background: #ffffff;
                    border-radius: 16px;
                    box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21);
                    margin-bottom: 26px;

                    .adwheel-tit {
                        width: 178px;
                        margin: 0 auto;
                        font-size: 20px;
                        font-weight: 500;
                        font-weight: bold;
                        color: #266ddc;
                        text-align: center;
                        background-image: url("../assets/img/list_tit_left.png"), url("../assets/img/list_tit_right.png");
                        background-repeat: no-repeat;
                        background-position: left center, right center;
                        background-size: 14px 14px;
                        margin-bottom: 18px;
                    }

                    .imgBox {
                        height: 880px;
                        overflow: hidden;

                        .imgs {
                            margin-top: 0;
                            transition: margin 500ms;

                            img {
                                width: 282px;
                                height: 160px;
                                display: block;
                                margin-bottom: 20px;
                                cursor: pointer;
                            }

                            img:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                .rightPushProject {
                    width: 314px;
                    padding: 16px 14px;
                    box-sizing: border-box;
                    background: #ffffff;
                    border-radius: 16px;
                    box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21);

                    .rightPushProject-tit {
                        width: 178px;
                        margin: 0 auto;
                        font-size: 20px;
                        font-weight: bold;
                        color: #266ddc;
                        text-align: center;
                        background-image: url("../assets/img/list_tit_left.png"), url("../assets/img/list_tit_right.png");
                        background-repeat: no-repeat;
                        background-position: left center, right center;
                        background-size: 14px 14px;
                        margin-bottom: 18px;
                    }

                    .rightPushItem {
                        padding: 12px 0 5px 0;
                        cursor: pointer;

                        .item-tit {
                            width: 284px;
                            margin-bottom: 12px;
                            font-size: 16px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .item-info {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .item-tags {
                                // width: 150px;
                                height: 20px;
                                background: #f2f7fd;
                                padding-left: 8px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                line-height: 20px;

                                span {
                                    margin-right: 10px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #266ddc;
                                }
                            }

                            .item-time {
                                font-size: 14px;
                                font-weight: 400;
                                color: #999999;
                            }
                        }
                    }

                    .rightPushItem:hover {
                        color: #266ddc;
                    }
                }
            }
        }
    }
</style>