import {
    get,
    post
} from './http'

//文章id详情（已登录）
export const getTopicVip = (data, param) => post('/pc/homePage/getInformation', data, param);
//文章id详情 （未登录）// /pc/homePage/getNonMemberInformation
export const getTopic = (data, param) => post('/pc/homePage/getInformation', data, param);
//文章收藏/取消
export const collectTopic = (data, param) => post('/pc/userCollection/save', data, param);
//文章收藏列表
export const collectList = (data, param) => post('/pc/userCollection/page/list', data, param);
//渠道统计
export const channelSave = (type) => post('/channel/save/' + type);